import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import App from './App';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box;
    background-color: black;
    color: white; */
  }

  html, body {
    width: 100%;
    height: 100%;
    font-family: 'GmarketSans', sans-serif;
    overflow-x: hidden;
    background-color: black;

  }

  #root {
    width: 100%;
    height: 100%;
  }

  스크롤바 스타일링
  ::-webkit-scrollbar {
    /* width: 12px; 스크롤바의 너비 */
  }

  ::-webkit-scrollbar-track {
    /* background: black; 스크롤바 트랙의 배경색 */
  }

  ::-webkit-scrollbar-thumb {
    /* background: #888; 스크롤바 손잡이의 색상 */
    /* border-radius: 6px; 스크롤바 손잡이의 모서리를 둥글게 */
  }

  ::-webkit-scrollbar-thumb:hover {
    /* background: #555; 스크롤바 손잡이 위에 마우스를 올렸을 때의 색상 */
  }

  
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* 초기 상태 */
  .animate-on-scroll {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }

  /* 스크롤 시 활성화 상태 */
  .animate {
    opacity: 1;
    transform: translateY(0);
  }
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <GlobalStyle />
    <App />
  </BrowserRouter>
);
