import React from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const drawLine = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const bottomIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const MainCon = styled.div`
  color: white;
  width: 70%;
  position: relative;
  top: -60px;

  .sec1 {
    display: flex;
    justify-content: space-between;
    opacity: 0; /* 처음에는 보이지 않음 */
    animation: ${fadeIn} 2s ease-out forwards;
    animation-delay: 2.5s; /* 2초의 hr 애니메이션과 3초의 딜레이 포함 */

    h1 {
      font-size: 130px;
    }

    div:first-child {
      /* 첫 번째 자식 div에 대한 스타일 */
    }

    div:last-child {
      width: 320px;
      height: 320px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid black;
      border-radius: 50%;
    }
  }

  .sec2 {
    opacity: 0; /* 처음에는 보이지 않음 */
    animation: ${bottomIn} 1s ease-out forwards;
    animation-delay: 2s; /* 2초의 hr 애니메이션과 2초의 딜레이 포함 */

    p {
      margin: 10px auto;
    }
    p:last-child {
      color: gray;
      font-size: 16px;
    }
  }

  .sec3 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    opacity: 0; /* 처음에는 보이지 않음 */
    animation: ${bottomIn} 1s ease-out forwards;
    animation-delay: 1.5s; /* 2초의 hr 애니메이션과 1초의 딜레이 포함 */

    div {
      width: 30%;
      text-align: center;
    }
    p {
      margin: 10px auto;
      font-size: 11px;
      padding: 10px 20px;
      width: 300px;
    }
  }

  hr {
    border: 0;
    height: 2px;
    background: white;
    animation: ${drawLine} 1.5s ease-out forwards; /* 2초의 hr 애니메이션 */
  }

  .sec4 {
    display: flex;
    justify-content: space-around; /* 버튼을 중앙에 정렬 */
    width: 100%;
    margin-top: 20px; /* 위에 약간의 간격 추가 */
    opacity: 0; /* 처음에는 보이지 않음 */
    animation: ${bottomIn} 1s ease-out forwards;
    animation-delay: 1s; /* 2초의 hr 애니메이션 포함 */
  }
  @media (max-width: 767px) {
    /* 모바일에서의 스타일 */
    width: 90%; /* 모바일에서 가로 크기 조절 */
    height: 90%;
    .sec1 {
      padding: 25px;
      flex-direction: column; /* 모바일에서 수직 정렬 */
      align-items: center; /* 중앙 정렬 */
    }
    .sec1 h1 {
      font-size: 50px; /* 모바일에서 폰트 크기 조절 */
      text-align: center;
    }
    .sec1 div:last-child {
      width: 200px; /* 모바일에서 이미지 크기 조절 */
      height: 200px;
      margin-top: 20px; /* 텍스트와 이미지 사이 간격 */
    }
    .sec2 p {
      font-size: 14px; /* 모바일에서 폰트 크기 조절 */
      text-align: center;
    }
    .sec2 p:last-child {
      font-size: 14px; /* 모바일에서 폰트 크기 조절 */
    }

    /* .sec3 {
      flex-direction: column; 
      align-items: center;
    } */

    .sec3 p {
      margin: 0;
      font-size: initial;
      padding: 0;
      width: 100%;
    }

    .sec4 {
      flex-direction: column; /* 모바일에서 수직 정렬 */
      align-items: center; /* 중앙 정렬 */
    }
  }
`;

const StyledButton = styled.a`
  text-decoration: none;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  border-radius: 40px;
  padding: 10px 50px;
  cursor: pointer;
  font-size: 45px;
  transition: background-color 0.5s, color 0.5s;

  &:hover {
    background-color: white;
    color: black;
  }
  @media (max-width: 767px) {
    padding: 5px 15px;
    font-size: 30px;
    transition: background-color 0.5s, color 0.5s;
    margin: 10px auto;
  }
`;

export default function MainComponent() {
  async function axioss() {
    console.log("엑시오스 요청 보내기");
    console.log("DB_HOST:", process.env.REACT_APP_DB_HOST);
    try {
      const response = await axios.get(`${process.env.REACT_APP_DB_HOST}/api`);
      console.log(response.data);
    } catch (error) {
      console.error("Axios 요청 에러:", error);
    }
  }

  return (
    <MainCon>
      <div className="sec1">
        <div>
          <h1>Full stack</h1>
          <h1>Developer</h1>
        </div>
        <div>
          <img src="/img/증명사진.jpg" alt="증명사진" />
        </div>
      </div>
      <div className="sec2">
        <p>안녕하세요. 끊임없이 성장하며</p>
        <p>
          혁신적인 솔루션을 제공하는 개발자가 되고 싶은 주니어 개발자 입니다!
        </p>
      </div>
      <hr />
      <div className="sec3">
        <div>
          <p></p>
        </div>
        <div>
          <p></p>
        </div>
        <div>
          <p></p>
        </div>
      </div>
      <div className="sec4">
        <StyledButton href="https://github.com/gusnl88" target="_blank">
          GITHUB
        </StyledButton>
        <StyledButton href="https://develkbh.tistory.com/" target="_blank">
          TSTORY
        </StyledButton>
        <StyledButton
          href="https://www.notion.so/PLANNING-f77724d2fb25409785baa4eec544ff3f?p=4275be0239cc41859472799195fd7438&pm=s"
          target="_blank"
        >
          NOTION
        </StyledButton>
      </div>
    </MainCon>
  );
}
