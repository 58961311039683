import React from "react";
import styled from "styled-components";
import MainComponent from "../components/Main/MainComponent";

const MainSection = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;

`;

export default function MainPage() {
  return (
    <MainSection>
      <MainComponent />
    </MainSection>
  );
}
