import React from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";

const Nav = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  color: #ffffff;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  font-weight: lighter;
  align-items: center;
  z-index: 1000;
  background-color: #000; /* 배경색 추가 */
  @media (max-width: 767px) {
    width: 92%;
  }
`;

const NavItem = styled(Link)`
  margin: 0 1rem;
  cursor: pointer;
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  
`;

const ScrollToTopButton = styled(Link)`
  border: 1px solid;
  border-radius: 50%;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 30px;
  color: white;
  z-index: 1;
  &:hover {
    background-color: #444;
    transition: background-color 0.5s ease-in;
  }
 
`;

const Navbar = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Nav>
        <NavItem smooth to="#home">
          Home
        </NavItem>
        <NavItem smooth to="#project">
          Project
        </NavItem>
        <NavItem smooth to="#skill">
          Skill
        </NavItem>
        <NavItem smooth to="#blog">
          Blog
        </NavItem>
      </Nav>
      <ScrollToTopButton smooth to="#home">
        ↑
      </ScrollToTopButton>
    </>
  );
};

export default Navbar;
