import styled from 'styled-components';
import WorkComponent from '../components/Main/WorkComponent';

const WorkSection = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`;

export default function WorkPage(){
    return(
        <WorkSection>
            <WorkComponent/>
        </WorkSection>
    )
}