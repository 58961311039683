import styled from 'styled-components';
import SkillComponent from '../components/Main/SkillComponent';

const SkillSection = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  color: white;
  background-color: black;

`;

export default function SkillPage() {
  return (
    <SkillSection>
      <SkillComponent/>
    </SkillSection>
  );
}
