import React from "react";
import styled, { keyframes } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { Autoplay } from 'swiper/modules'; // Autoplay 모듈 임포트

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ProjectSection = styled.div`
  height: 100%;
  width: 80%;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.5s; /* 지연 시간 설정 */
  align-content: center;

  h1:first-child {
    font-size: 60px;
    padding: 15px 0px;
  }

  @media (max-width: 767px) {
    width: 100%;
    background-color: black;
    h1:first-child {
      font-size: 40px;
      padding: 10px;
    }
  }
`;

const ProjectBox = styled.div`
  height: 80%;
  width: 100%; /* 가로로 100% 차지 */
  display: flex;
  justify-content: space-around; /* 카드 사이 간격 조절 */
  padding: 20px 0; /* 위아래 패딩 추가 */

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
  }
`;

const ProjectCard = styled.div`
  width: 90%;
  height: 100%; /* 부모의 높이 100% 차지 */
  display: flex;
  flex-direction: column;
  position: relative; /* 자식 요소의 위치 설정을 위해 추가 */

  .img-container {
    width: 100%;
    height: 400px; /* 이미지가 높이의 70% 차지 */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* 이미지가 박스 밖으로 나가지 않도록 */
  }

  .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 이미지가 박스를 커버하도록 */
    border-radius: 5px;
  }

  .text-container {
    width: 100%;
    height: 30%; /* 설명이 높이의 30% 차지 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px auto;
  }

  .text-container h1 {
    font-size: 20px;
  }

  .text-container p {
    font-size: 14px;
    color: #464646;
  }

  .hover-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: opacity 0.3s ease;

    a {
      text-decoration: none;
      color: white;
    }
  }

  &:hover .hover-overlay {
    opacity: 1;
  }

  @media (max-width: 767px) {
    .img-container {
      height: 200px; /* 모바일에서 이미지 높이 조정 */
    }

    .text-container {
      height: auto; /* 모바일에서 텍스트 컨테이너 높이 자동 조정 */
      margin: 10px auto;
    }

    .text-container h1 {
      font-size: 16px;
    }

    .text-container p {
      font-size: 12px;
    }

    .hover-overlay {
      padding: 10px;
    }
  }
`;

export default function ProjectComponent() {
  const projects = [
    {
      id: 1,
      title: "Gabojago Plan",
      content:"원거리 사람들과 실시간 채팅 및 여행 일정 계획 가능 웹사이트 나만의 일정을 꾸며 공유하며 꾸미기 활동을 제공하고 일정수립을 위한 정보제공 및 상품판매 가능",
      skill:"DBA,전체적인 디자인, 카카오페이결제기능, 판매게시판crud , 찜,좋아요 가능",
      imageUrl: "/img/portfolio/gabojago.JPG",
      gitUrl: "https://github.com/gusnl88/GabojagoUser"
    },
    {
      id: 2,
      title: "SesacHub",
      content:"회의실, 상담부스 등의 공간 예약을 오프라인으로만 해야하는 불편함을 해소하고자 함 SeSAC 교육생 및 관계자만의 Private한 온라인 커뮤니티 공간에서 자유로운 소통 및 정보 공유오프라인으로 실시하던 공간 예약을 온라인으로 가능하게 해 교육생과 관리자 모두 편리해짐 -서비스 타겟- |nSeSAC 교육과정 교육생 및 수료자|n공간 예약을 하려는 SeSAC 관계자|n간편하게 예약 관리를 하고 싶은 관리자",
      skill:"DBA,전체적인 디자인, 라우터 설정, API 생성, 업로드 관리,관리자페이지",
      imageUrl: "/img/portfolio/sesachub.JPG",
      gitUrl: "https://github.com/gusnl88/sesachub"
    },
    {
      id: 3,
      title: "CatchLiars",
      content:"이번 프로젝트를 통해 게임 개발에 대한 새로운 경험을 얻을 수 있었습니다. 특히, 마피아 게임의 시작부터 종료까지의 모든 과정을 직접 구현하면서 소켓 통신을 통해 클라이언트 상태 관리에 대한 이해도를 높일 수 있었습니다. 또한, 팀원들과의 협업을 통해 채팅 기능과 화면 구성, 로그인 관리 등 다양한 기능을 구현하는 경험을 하게 되어 매우 유익했습니다. 이러한 경험을 토대로 앞으로의 프로젝트에서도 보다 전문적이고 효율적인 개발을 진행할 수 있을 것으로 기대됩니다.",
      skill:"라이어 게임 전체 구현, 실시간 소켓 통신으로 게임 진행, 프로젝트 초기 설정 및 클라이언트 구축, 로그인 관리 리덕스 구현",
      imageUrl: "/img/portfolio/catchliars.JPG",
      gitUrl: "https://github.com/gusnl88/CatchLiars_front"
    },
    // {
    //   id: 4,
    //   title: "Project 4",
    //   description: "Description of project 4",
    //   imageUrl: "/img/마피아게임대기방.JPG",
    //   gitUrl: "https://github.com/user/project4"
    // },
  ];

  return (
    <ProjectSection>
      <h1>FEATURED PROJECTS</h1>
      <Swiper 
        spaceBetween={30} 
        slidesPerView={1} 
        loop={true} 
        autoplay={{ delay: 3000 }}  // 3초마다 슬라이드 변경
        modules={[Autoplay]} // Autoplay 모듈 추가
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {projects.map((project) => (
          <SwiperSlide key={project.id}>
            <ProjectBox>
              <ProjectCard>
                <div className="img-container">
                  <img src={project.imageUrl} alt={project.title} />
                </div>
                <div className="text-container">
                  <h1>{project.title}</h1>
                  <p>
                    {project.content}
                  </p><br />
                  <p>
                    기여도 : {project.skill}
                  </p>
                </div>
                <div className="hover-overlay">
                  <p>{project.title}</p><br />
                  <a href={project.gitUrl} target="_blank" rel="noopener noreferrer" >
                    <h3>Git 저장소 가기</h3>
                  </a>
                </div>
              </ProjectCard>
            </ProjectBox>
          </SwiperSlide>
        ))}
      </Swiper>
    </ProjectSection>
  );
}
