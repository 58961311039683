import React, { useEffect, useRef, useState } from 'react';
import MainPage from "./pages/MainPage";
import SkillPage from "./pages/SkillPage";
import AboutMePage from "./pages/AboutMePage"; // 새로운 페이지 추가
import Navbar from './components/Navbar/Navbar';
import ProjectPage from './pages/ProjectPage';
import WorkPage from './pages/WorkPage';
import IngPage from './pages/IngPage';

function App() {
  const [isMainVisible, setIsMainVisible] = useState(false);//메인
  const [isSkillVisible, setIsSkillVisible] = useState(false);//스킬
  const [isAboutMeVisible, setIsAboutMeVisible] = useState(false); // 어바웃미
  const [isProjectVisible, setIsProjectVisible] = useState(false); // 프로젝트
  const [isWorksVisible, setIsWorksVisible] = useState(false); // 경력및교육
  const [isIngVisible,setIsIngVisible] = useState(false); // 계속 업데이트중

  const mainRef = useRef(null);// 메인 Ref
  const skillRef = useRef(null);//스킬 Ref
  const aboutMeRef = useRef(null); // 어바웃미 Ref 
  const projectRef = useRef(null);// 프로젝트 Ref
  const worksRef = useRef(null);// 프로젝트 Ref
  const ingRef = useRef(null);// 계속 업데이트중 Ref

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target.id === "home") {
            setIsMainVisible(true);
          } else if (entry.target.id === "skill") {
            setIsSkillVisible(true);
          } else if (entry.target.id === "aboutMe") { // 새로운 조건 추가
            setIsAboutMeVisible(true);
          }else if(entry.target.id === "project"){
            setIsProjectVisible(true);
          }
          else if(entry.target.id === "works"){
            setIsWorksVisible(true);
          }
          else if(entry.target.id === "ing"){
            setIsIngVisible(true);
          }
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (mainRef.current) observer.observe(mainRef.current);//메인  Ref 관찰 추가
    if (skillRef.current) observer.observe(skillRef.current);//스킬  Ref 관찰 추가
    if (aboutMeRef.current) observer.observe(aboutMeRef.current); // 어바웃미 Ref 관찰 추가
    if (projectRef.current) observer.observe(projectRef.current);//프로젝트 Ref 관찰 추가
    if (worksRef.current) observer.observe(worksRef.current);//worsEx Ref 관찰 추가
    if (ingRef.current) observer.observe(ingRef.current);//ing Ref 관찰 추가
    return () => {
      if (mainRef.current) observer.unobserve(mainRef.current); //메인  Ref 관찰 해제
      if (skillRef.current) observer.unobserve(skillRef.current);//스킬  Ref 관찰 해제
      if (aboutMeRef.current) observer.unobserve(aboutMeRef.current); // 어바웃미 Ref 관찰 해제
      if (projectRef.current) observer.unobserve(projectRef.current);//프로젝트  Ref 관찰 해제
      if (worksRef.current) observer.unobserve(worksRef.current);//worsEx  Ref 관찰 해제
      if (ingRef.current) observer.unobserve(ingRef.current);//worsEx  Ref 관찰 해제
    };
  }, []);

  return (
    <>
      <Navbar />
      <section id="home" ref={mainRef} style={{ minHeight: '100vh' }}>
        {isMainVisible && <MainPage />}
      </section>
      <section id="aboutMe" ref={aboutMeRef} style={{ minHeight: '100vh' }}> {/* 새로운 섹션 추가 */}
        {isAboutMeVisible && <AboutMePage />}
      </section>
      <section id="project" ref={projectRef} style={{ minHeight: '100vh' }}>
        {isProjectVisible && <ProjectPage />}
      </section>
      <section id="skill" ref={skillRef} style={{ minHeight: '100vh' }}>
        {isSkillVisible && <SkillPage />}
      </section>
      <section id="works" ref={worksRef} style={{ minHeight: '100vh' }}>
        {isWorksVisible && <WorkPage />}
      </section>
      <section id="ing" ref={ingRef} style={{ minHeight: '100vh' }}>
        {isIngVisible && <IngPage />}
      </section>
    </>
  );
}

export default App;
