import React from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SkillSection = styled.div`
  display: flex;
  justify-content: center;
`;

const SkillBox = styled.div`
  width: 80vw;
  height: 80vh;
  display: flex;

  h1, h2 {
    opacity: 0;
    animation: ${fadeIn} 1s forwards;
  }

  h1 {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 50px;
    animation-delay: 0.5s;
  }

  h2 {
    font-size: 50px;
    animation-delay: 1.5s;
  }

  .skill_text {
    width: 40vw;
  }

  .skill_box {
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .skill_first,
    .skill_last {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .skill_first > div,
    .skill_last > div {
      background-color: #232323;
      width: 20vw;
      height: 35vh;
      margin: 20px 10px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      transition: opacity 0.3s ease;
      overflow: hidden;
      opacity: 0;
      animation: ${fadeIn} 3s forwards;
        transition: opacity 0.6s ease;

      &:hover {
        opacity: 1;
      }

      &:hover .skill_details {
        cursor: pointer;
        opacity: 1;
        background-color: #636363;
        span {
          background-color: #636363;
        }
      }

      span {
        background-color: #232323;
        border: 1px solid;
        border-radius: 10px;
        padding: 5px;
        margin: 2px;
      }

      img {
        width: 40%;
        height: 40%;
      }

      p {
        background-color: #232323;
        font-size: 40px;
        font-weight: bold;
      }

      .skill_details {
        background-color: #232323;
        width: 20vw;
        height: 35vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        text-align: center;
        opacity: 0;
        transition: opacity 0.6s ease;
      }
    }

    .front {
      animation-delay: 2s;
    }

    .database {
      animation-delay: 2.5s;
    }

    .back {
      animation-delay: 3s;
    }

    .platforms {
      animation-delay: 3.5s;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    h1,h2{
      font-size: 30px;
    }
    .skill_box{

      .skill_first > div,
      .skill_last > div {
        width: 35vw;
        height: 30vh;
        position: relative;
        left: 10px auto;
        left: 35px;
        .skill_details{
          width: 35vw;

        }
        p{
          font-size: 14px;
        }
        span{
          padding: 3px;
        }
      }
    }
  }
`;

export default function SkillComponent() {
  return (
    <SkillSection>
      <SkillBox>
        <div className="skill_text">
          <h1>MY</h1>
          <h1>TECHNOLOGY</h1>
          <h2>STACK</h2>
        </div>
        <div className="skill_box">
          <div className="skill_first">
            <div className="front">
              <img src="/img/front.jpg" alt="front-end" />
              <p>FRONT-END</p>
              <div className="skill_details">
                <span>React</span>
                <span>CSS</span>
                <span>jQuery</span>
                <span>JavaScript</span>
                <span>TypeScript</span>
              </div>
            </div>
            <div className="database">
              <img src="/img/database.jpg" alt="database" />
              <p>DATABASE</p>
              <div className="skill_details">
                <span>MySQL</span>
                <span>MS-SQL</span>
                <span>MariaDB</span>
              </div>
            </div>
          </div>
          <div className="skill_last">
            <div className="back">
              <img src="/img/back.jpg" alt="back-end" />
              <p>BACK-END</p>
              <div className="skill_details">
                <span>Node.js</span>
                <span>Java</span>
                <span>Spring</span>
                <span>SpringBoot</span>
              </div>
            </div>
            <div className="platforms">
              <img src="/img/platforms.jpg" alt="platforms" />
              <p>PLATFORMS</p>
              <div className="skill_details">
                <span>AWS</span>
                <span>NaverCloud</span>
                <span>CloudType</span>
              </div>
            </div>
          </div>
        </div>
      </SkillBox>
    </SkillSection>
  );
}
